.bryan-app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
}

.bryan-button-container button {
    padding: 15px 25px;
    margin: 10px;
    font-size: clamp(13px, 3vw, 18px) !important;
    border: none;
    cursor: pointer;
    background-color: #062E5F;
    color: white;
    border-radius: 5px;
}

.bryan-button-container button:hover,
.bryan-thumbnail:hover {
    transform: scale(1.1);
}

.grid-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    margin-top: 20px;
    padding: 10px;
}

.bryan-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 22px;
    margin-top: 120px;
}

.bryan-thumbnail {
    width: 100px;
    height: 100px;
    cursor: pointer;
}
@media (min-width: 800px) {
    .bryan-grid-container {
        gap: 35px;
    }    
    .bryan-thumbnail {
        width: 150px;
        height: 150px;
        cursor: pointer;
    }
  }

.bryan-fullscreen-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bryan-fullscreen-image {
    width: 95vw;
    height: 95vh;
    object-fit: contain;
}

.bryan-exit-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: clamp(16px, 4vw, 24px) !important;
    background-color: transparent;
    color: #062E5F;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.bryan-next-area {
    width: 30%;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;    
    font-size: clamp(16px, 4vw, 24px) !important;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.bryan-next-btn {
    width: 100%;
}

.bryan-display-image {
    cursor: pointer;
    width: 70%;
    height: 100vh;
    object-fit: contain;
}